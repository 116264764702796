import React, { useRef } from 'react';

import { FlagIcon, MenuContainer, MenuList } from './styles';
import { useDetectOutsideClick } from '../../utils/useDetectOutsideClick';

const allLanguages = ['sv', 'en'];

export default ({ language, setLanguage, ...props }) => {
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  const onClick = () => setIsActive(!isActive);
  const onSetLanguage = lang => {
    setLanguage(lang);
    setIsActive(!isActive);
  };
  return (
    <MenuContainer>
      {!isActive && <FlagIcon onClick={onClick} icon={language} />}
      {isActive && (
        <MenuList ref={dropdownRef}>
          {allLanguages.map(lang => (
            <FlagIcon
              onClick={() => onSetLanguage(lang)}
              key={lang}
              icon={lang}
            />
          ))}
        </MenuList>
      )}
    </MenuContainer>
  );
};
