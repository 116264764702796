import React from 'react';
import Helmet from 'react-helmet';

import { Wrapper } from 'components';
import BuilderComponent from 'components/BuilderComponent';

export default React.memo(() => (
  <Wrapper>
    <Helmet>
      <title>Kontakta våra IT-rekrytererare</title>
      <meta
        name="description"
        content="Söker du en expert inom IT-rekrytering i Göteborg? | Kontakta en av våra seniora IT-rekryterare för att få hjälp med att anställa utvecklare."
      />
    </Helmet>
    <BuilderComponent
      entries={{
        sv: 'd89b1040bce44e8e9662902696198461',
        en: 'c70d41d0022543a192ab3a664cd043d8',
      }}
    />
  </Wrapper>
));
