const avatarList = [
  'Avatars_Other_New-01.jpg',
  'Avatars_Other_New-02.jpg',
  'Avatars_Other_New-03.jpg',
  'Avatars_Other_New-04.jpg',
  'Avatars_Other_New-05.jpg',
  'Avatars_Other_New-06.jpg',
  'Avatars_Other_New-07.jpg',
  'Avatars_Other_New-08.jpg',
  'Avatars_Other_New-09.jpg',
  'Avatars_Other_New-10.jpg',
  'Avatars_Female_New-01.jpg',
  'Avatars_Female_New-02.jpg',
  'Avatars_Female_New-03.jpg',
  'Avatars_Female_New-04.jpg',
  'Avatars_Female_New-05.jpg',
  'Avatars_Female_New-06.jpg',
  'Avatars_Female_New-07.jpg',
  'Avatars_Female_New-09.jpg',
  'Avatars_Female_New-10.jpg',
  'Avatars_Male_New-01.png',
  'Avatars_Male_New-02.png',
  'Avatars_Male_New-03.png',
  'Avatars_Male_New-04.png',
  'Avatars_Male_New-05.png',
  'Avatars_Male_New-06.png',
  'Avatars_Male_New-07.png',
  'Avatars_Male_New-08.png',
  'Avatars_Male_New-09.png',
  'Avatars_Male_New-10.png',
  'Avatars_Other_New-11.jpg',
  'Avatars_Other_New-12.jpg',
  'Avatars_Other_New-13.jpg',
  'Avatars_Other_New-14.jpg',
  'Avatars_Other_New-15.jpg',
  'Avatars_Other-27.png',
  'Avatars_Other-29.png',
  'Avatars_Other-26.png',
  'Avatars_Other-10.png',
  'Avatars_Other-09.png',
  'Avatars_Other-08.png',
  'Avatars_Other-07.png',
  'Avatars_Other-21.png',
  'Avatars_Other-25.png',
  'Avatars_Other-11.png',
  'Avatars_Other-15.png',
  'Avatars_Other-14.png',
  'Avatars_Female_New-11.jpg',
  'Avatars_Female_New-12.jpg',
  'Avatars_Female_New-13.jpg',
  'Avatars_Female_New-14.jpg',
  'Avatars_Female_New-15.jpg',
  'Avatars_Female-01.png',
  'Avatars_Female-02.png',
  'Avatars_Female-03.png',
  'Avatars_Female-04.png',
  'Avatars_Female-05.png',
  'Avatars_Male_New-11.png',
  'Avatars_Other-04.png',
  'Avatars_Other-03.png',
  'Avatars_Other-01.png',
  'Avatars_Other-13.png',
  'Avatars_Other-17.png',
  'Avatars_Male_New-12.png',
  'Avatars_Male_New-13.png',
  'Avatars_Male_New-14.png',
  'Avatars_Male_New-15.png',
  'Avatars_Male_New-16.png',
  'Avatars_Male-01.png',
  'Avatars_Male-02.png',
  'Avatars_Male-03.png',
  'Avatars_Male-04.png',
  'Avatars_Other-30.png',
  'Avatars_Other-28.png',
  'Avatars_Other-23.png',
  'Avatars_Other-22.png',
  'Avatars_Other-05.png',
  'Avatars_Female-06.png',
  'Avatars_Female-07.png',
  'Avatars_Female-08.png',
  'Avatars_Female-09.png',
  'Avatars_Female-10.png',
  'Avatars_Female-11.png',
  'Avatars_Female-12.png',
  'Avatars_Female-13.png',
  'Avatars_Female-14.png',
  'Avatars_Female-15.png',
  'Avatars_Male-05.png',
  'Avatars_Male-06.png',
  'Avatars_Male-07.png',
  'Avatars_Male-08.png',
  'Avatars_Male-09.png',
  'Avatars_Male-10.png',
  'Avatars_Male-11.png',
  'Avatars_Male-12.png',
  'Avatars_Male-13.png',
  'Avatars_Male-14.png',
  'Avatars_Female-16.png',
  'Avatars_Female-17.png',
  'Avatars_Female-18.png',
  'Avatars_Female-19.png',
  'Avatars_Female-20.png',
  'Avatars_Female-21.png',
  'Avatars_Female-22.png',
  'Avatars_Female-23.png',
  'Avatars_Female-24.png',
  'Avatars_Female-25.png',
  'Avatars_Female-26.png',
  'Avatars_Female-27.png',
  'Avatars_Male-15.png',
  'Avatars_Male-16.png',
  'Avatars_Male-17.png',
  'Avatars_Male-18.png',
  'Avatars_Male-19.png',
  'Avatars_Male-21.png',
  'Avatars_Male-22.png',
  'Avatars_Male-23.png',
  'Avatars_Male-24.png',
  'Avatars_Male-25.png',
  'Avatars_Male-26.png',
  'Avatars_Male-27.png',
  'Avatars_Male-28.png',
  'Avatars_Male-29.png',
  'Avatars_Male-30.png',
  'Avatars_Male-32.png',
  'Avatars_Other-04.png',
  'Avatars_Other-06.png',
  'Avatars_Other-18.png',
  'Avatars_Other-19.png',
  'Avatars_Other-20.png',
  'Avatars_Other-24.png',
];

let avatars = [];

avatarList.map(avatarName => {
  return (avatars = [...avatars, require(`./${avatarName}`)]);
});

export default avatars;
