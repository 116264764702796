import styled from 'styled-components';

import { colors } from 'components';
import { FOOTER_HEIGHT } from 'utils';

export const LoadingLabel = styled.span`
  margin-bottom: 20px;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 300;
  color: ${colors.darkGray};
`;

export const Container = styled.div`
  background: ${colors.lightBlue};
  width: 100vw;
  height: calc(100vh - ${FOOTER_HEIGHT}px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 150px;
`;
