import React from 'react';
import styled, { css } from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import ReactSelect from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { FOOTER_HEIGHT } from 'utils';

export const colors = {
  white: '#ffffff',
  black: '#000000',
  bgGray: '#f5f7fa',
  lightGray: '#e5e5e5',
  lightGray2: '#888888',
  darkGray: '#555555',
  darkGray2: '#222222',
  lightBlue: '#e4edf3',
  darkBlue: '#295c88',
  lightGreen: '#36e0b7',
  darkOrange: '#906B37',
  lightOrange: '#F7e2C5',
  red: '#de5246',
};

export const Select = props => {
  const customStyles = {
    control: provided => ({
      ...provided,
      border: 'none',
      boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
    }),
    menu: provided => ({
      ...provided,
      border: 'none',
      boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
    }),
  };
  return <ReactSelect styles={customStyles} {...props} />;
};

export const Hr = styled.hr`
  border: 0;
  height: 1px;
  background: ${colors.lightGray2};
  width: 100%;
  margin: 0 0 2px;

  ${props =>
    props.light &&
    css`
      background: ${colors.lightGray};
    `}
`;

export const Wrapper = styled.div`
  background: ${props => props.color || colors.bgGray};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0;
  margin-top: 60px;
  min-height: calc(100% - ${FOOTER_HEIGHT}px);

  :after {
    content: '';
    flex: auto;
  }

  ${props =>
    props.noMargin &&
    css`
      margin-top: 0;
    `}
`;

export const TitleAndIcon = styled.div`
  width: 100%;
  display: flex;
  padding: 2px;
  justify-content: ${props =>
    props.spaceBetween ? 'space-between' : 'flex-start'};
  align-items: center;
  color: ${props => (props.color ? props.color : colors.black)};
`;

export const OtherInfoContainer = styled.div`
  width: 100%;
  padding: 5px 10px 10px 10px;
  border: 1px solid ${colors.darkBlue};
  border-radius: 4px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const FormFieldContainer = styled.div`
  width: 100% !important;

  ${props =>
    props.padding &&
    css`
      padding-bottom: 5px;
    `}
`;

export const Container = styled.div`
  width: 80%;
  max-width: 1200px;
  padding: 30px 0;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1366px) {
    width: 100%!important;
  }

  ${props =>
    props.width &&
    css`
      width: ${props.width}!important;
    `}

  ${props =>
    props.color &&
    css`
      background: ${props.color};
      border-radius: 10px;
      padding: 20px;
      margin-top: 50px;
      margin-bottom: 50px;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    `}

    ${props =>
      props.noPadding &&
      css`
        padding: 0px !important;
      `}

  ${props =>
    props.half &&
    css`
      width: 50% !important;
    `}

  ${props =>
    props.wide &&
    css`
      @media (max-width: 1024px) {
        flex-direction: column;
      }
    `}

  @media (max-width: 667px) {
    flex-direction: column;
  }

  ${props =>
    props.column &&
    css`
      flex-direction: column;
    `}

  ${props =>
    props.full &&
    css`
      max-width: none;
      width: 100% !important;
    `}

  ${props =>
    props.center &&
    css`
      justify-content: center;
    `}
  
  ${props =>
    props.column &&
    props.center &&
    css`
      align-items: center;
    `}
  
  ${props =>
    props.marginTop &&
    css`
      margin-top: 20px;
    `}
  
  ${props =>
    props.marginLeft &&
    css`
      margin-left: 20px;
    `}

  ${props =>
    props.marginBottom &&
    css`
      margin-bottom: 60px;
    `}

  ${props =>
    props.padding &&
    css`
      padding: 80px 0;

      @media (max-width: 1024px) {
        padding: 30px 0;
      }
    `}
  ${props =>
    props.agreeCheckBox &&
    css`
      width: 95% !important;
    `}
          
`;

export const Hero = styled.div`
  background: url(${props => props.image});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: ${props => props.height || 500}px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  ${props =>
    props.overlay &&
    css`
      :after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
        ${props.overlay === 'top' &&
          `background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0))`}
      }
    `}
`;

export const HeroTitle = styled.h1`
  color: ${colors.white};
  z-index: 1;
`;

export const Button = ({ children, type, ...props }) => (
  <ButtonElement type={type} {...props}>
    {children}
  </ButtonElement>
);

export const Form = styled.form.attrs(({ onSubmit }) => ({
  onSubmit: e => {
    e.preventDefault();
    onSubmit();
  },
}))`
  display: flex;
  flex-direction: column;
`;

export const Tag = styled.div`
  text-transform: uppercase;
  background: ${colors.darkBlue};
  padding: 5px 15px;
  color: ${colors.white};
  font-size: 11px;
  font-weight: bolder;
  border-radius: 25px;
`;

export const Icon = styled(FontAwesomeIcon)`
  color: ${props => (props.color ? props.color : colors.darkBlue)};
  cursor: pointer;
  margin-left: 10px;
  font-size: 18px;
  :hover {
    color: ${colors.darkGray2};
  }
`;

// const ButtonContainer = styled.div`
//   margin: auto;

//   ${props =>
//     props.noVerticalMargin &&
//     css`
//       margin: 0 auto;
//     `}

//   ${props =>
//     props.noMargin &&
//     css`
//       margin: 0 !important;
//     `}

//   ${props =>
//     props.marginRight &&
//     css`
//       margin-right: 10px !important;
//     `}

//   ${props =>
//     props.onClick &&
//     props.isMenu &&
//     css`
//       margin: 0 0 0 20px;
//       @media (max-width: 1024px) {
//         margin-left: 0;
//       }
//     `}
//   ${props =>
//     props.disabled &&
//     css`
//       cursor: not-allowed;
//     `}

//     ${props =>
//     props.right &&
//     css`
//         float: right;
//       `}
// `;

const ButtonElement = styled.button`
  text-transform: uppercase;
  padding: 7px 15px;
  border-radius: 25px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1px;
  color: ${colors.white};
  transition: padding 0.25s ease-in-out;
  border: 2px solid transparent;
  background: transparent;
  user-select: none;
  appearance: none !important;

  :focus {
    outline: none;
  }

  :hover {
    background: ${colors.white};
    color: ${colors.darkBlue};
    text-decoration: none;
  }

  ${props =>
    props.isScrolled &&
    css`
      padding-top: 3px;
      padding-bottom: 3px;
    `}

  ${props =>
    props.primary &&
    css`
      background: ${colors.darkBlue};
    `}

  ${props =>
    props.border &&
    css`
      :hover {
        border: 2px solid ${colors.darkBlue};
      }
    `}

  ${props =>
    props.danger &&
    css`
      background: ${colors.red};
      :hover {
        border: 2px solid ${colors.red};
        color: ${colors.red};
      }
    `}

  ${props =>
    props.secondary &&
    css`
      border-color: ${colors.white};
    `}

  ${props =>
    props.small &&
    css`
      padding: 6px 18px;
      font-size: 12px;
    `}

    ${props =>
      props.xsmall &&
      css`
        padding: 3px 9px;
        font-size: 8px;
      `}

  ${props =>
    props.isMenu &&
    css`
      @media (max-width: 1024px) {
        background: none;
        border: none;
        padding: 0;
        color: ${colors.darkGray2};

        :hover {
          background: none;
        }
      }
    `}

  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
    
  ${props =>
    props.MarginRight &&
    css`
      margin-right: ${props.MarginRight}px!important;
    `}
`;

export const Link = styled(RouterLink)`
  text-decoration: none;
  color: unset;

  :hover {
    color: unset;
    text-decoration: none;
  }
`;

export const A = styled.a`
  text-decoration: none;
  color: unset;

  :hover {
    color: unset;
    text-decoration: none;
  }
`;

export const Section = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  z-index: 0;

  ${props =>
    props.dark &&
    css`
      background: ${colors.darkBlue};
    `}

  ${props =>
    props.backgroundImage &&
    css`
      background-image: url(${props.backgroundImage});
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      min-height: 600px;
    `}
`;

export const Header = styled.h1`
  color: ${colors.darkBlue};
  font-weight: 600;
  margin-bottom: 20px;
`;

export const SectionHeader = styled.span`
  text-transform: uppercase;
  font-weight: 300;
  font-size: 30px;
  margin-bottom: 35px;
  color: ${colors.white};
    ${props =>
      props.margin &&
      css`
        margin-top: 100px;
      `}
    ${props =>
      props.bold &&
      css`
        font-weight: bold;
      `}
    ${props =>
      props.large &&
      css`
        font-size: 40px;
      `}
    ${props =>
      props.dark &&
      css`
        color: ${colors.darkBlue};
      `};
`;

export const Input = styled.input`
  font-size: 16px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  padding: 8px 10px 7px;
  border: none;
  border-radius: 5px;
  background: ${colors.white};
  outline: none;
  color: ${colors.darkGray};

  :focus {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.36);
  }

  ${props =>
    props.full &&
    css`
      width: 100%;
    `}

  ${props =>
    props.marginBottom &&
    css`
      margin-bottom: 0.5rem;
    `}
`;

export const RadioInput = props => {
  return props.options.map((option, index) => {
    return [
      <div key={`r${index}`}>
        <input
          type="radio"
          checked={props.value === option}
          name={props.name}
          onChange={props.onChange}
          value={option}
        />
        <Label right>{option}</Label>
      </div>,
    ];
  });
};

export const RangeInput = props => {
  return (
    <input
      type="range"
      style={{ width: '100%', margin: '0px 5px' }}
      value={props.value}
      min={props.min}
      max={props.max}
      onChange={props.onChange}
    />
  );
};

export const DropDownInput = props => {
  return (
    <select
      style={{
        width: '100%',
        marginBottom: '0.5rem',
        fontSize: '16px',
        boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.25)',
        padding: '8px 10px 7px',
        border: 'none',
        borderRadius: '5px',
        background: colors.white,
        outline: 'none',
        color: colors.darkGray,
      }}
      onChange={props.onChange}
      value={props.value}
    >
      <option defaultValue="">{props.placeholder}</option>
      {props.options.map((option, index) => {
        return (
          <option key={index} value={option}>
            {' '}
            {option}
          </option>
        );
      })}
    </select>
  );
};

export const FileInput = styled.input`
  font-size: 16px;
  padding: 8px 10px 7px 0px;
  border: none;
  background: ${colors.white};
  outline: none;
  color: ${colors.darkGray};

  :focus {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.36);
  }

  ${props =>
    props.full &&
    css`
      width: 100%;
    `}

  ${props =>
    props.marginBottom &&
    css`
      margin-bottom: 0.5rem;
    `}

  ${props =>
    props.padding &&
    css`
      padding: 40px 0px 0px 20px;
    `}
`;

export const InfoText = styled.span`
  color: #555555;
  font-size: 14px;
  margin-bottom: 10px;

  ${props =>
    props.error &&
    css`
      color: red;
    `}

  ${props =>
    props.success &&
    css`
      color: green;
    `}
`;

export const CollapsableSectionForm = styled.div`
  width: 100%;
  display: none;

  ${props =>
    props.show &&
    css`
      display: block;
    `}
`;

export const CollapsableSection = styled.div`
  width: 100%;
  display: none;
  overflow-y: scroll;
  max-height: 500px;

  ${props =>
    props.show &&
    css`
      display: block;
    `}
`;

export const ToggleCollapsableSection = styled.button`
  background-color: ${colors.darkGray};
  color: white;
  cursor: pointer;
  padding: 10px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;

  :hover {
    background-color: ${colors.darkBlue};
  }
`;

export const DefaultAvatars = styled.img`
  max-width: 16%;
  max-height: 16%;
  margin: 1px;
  cursor: pointer;
`;

export const Avatar = styled.label`
  flex-shrink: 0;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  width: 120px;
  height: 120px;
  margin-bottom: 15px;
  margin-left: 15px;
  background-image: url(${props => props.image || ''});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
  overflow: hidden;

  @media (max-width: 667px) {
    width: 100px;
    height: 100px;
    margin: 0 10px;
  }
`;

export const Note = styled.span`
  color: #555555;
  font-size: 12px;

  ${props =>
    props.marginTop &&
    css`
      margin-top: 10px;
    `}

  ${props =>
    !props.noMargin &&
    css`
      margin-bottom: 15px !important;
    `}

  ${props =>
    props.error &&
    css`
      color: red;
    `}
`;

export const Checkbox = ({ checked, label, right, onChange }) => {
  const input = (
    <CheckboxInput right={right} checked={checked} onChange={onChange} />
  );
  const inputLabel = <Label right={right}>{label}</Label>;

  return (
    <CheckboxContainer>
      {right ? (
        <label>
          {input}
          {inputLabel}
        </label>
      ) : (
        <label>
          {inputLabel}
          {input}
        </label>
      )}
    </CheckboxContainer>
  );
};

const CheckboxContainer = styled.div``;

export const CheckboxInput = styled.input.attrs({ type: 'checkbox' })`
  outline: none;

  ${props =>
    !props.right &&
    css`
      margin-left: 5px;
    `}
`;

export const Label = styled.span`
  font-size: 16px;
  color: ${colors.darkGray};

  ${props =>
    props.right &&
    css`
      margin-left: 5px;
    `}
`;

export const FormLabel = styled.span`
  font-size: 0.85em;
  margin-bottom: 0.2rem;
  color: ${colors.darkGray};

  ${props =>
    props.right &&
    css`
      margin-left: 5px;
    `}
`;
