import React from 'react';
import Helmet from 'react-helmet';

import { Wrapper } from 'components';
import BuilderComponent from 'components/BuilderComponent';

export default React.memo(() => (
  <Wrapper>
    <Helmet>
      <title>Talangprogram</title>
      <meta
        name="description"
        content="Talangprogram i Göteborg | Ge juniora utvecklare och nyexaminerade programmerare en chans genom ett skräddarsytt talangprogram."
      />
    </Helmet>
    <BuilderComponent
      entries={{
        sv: '19ba73ee496f440ca0218fc32c277c1f',
        en: 'e3be64dc489c46deb4643ce662233deb',
      }}
    />
  </Wrapper>
));
