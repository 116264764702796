import {
  GET_JOB_REQUEST,
  GET_JOB_SUCCESS,
  GET_JOB_FAILURE,
  UPDATE_CANDIDATE_REQUEST,
  UPDATE_CANDIDATE_SUCCESS,
  UPDATE_CANDIDATE_FAILURE,
} from './constants';

import PrimusHandler from 'utils/primus';

export const getJob = id => dispatch => {
  try {
    dispatch({ type: GET_JOB_REQUEST });
    PrimusHandler.sendRequest(
      {
        _handler: 'load-job',
        _id: id,
        isReact: true,
      },
      GET_JOB_SUCCESS,
    );
  } catch (error) {
    dispatch({ type: GET_JOB_FAILURE, error });
  }
};

export const updateApplication = data => dispatch => {
  try {
    dispatch({ type: UPDATE_CANDIDATE_REQUEST });
    PrimusHandler.sendRequest(data, UPDATE_CANDIDATE_SUCCESS);
  } catch (error) {
    dispatch({ type: UPDATE_CANDIDATE_FAILURE, error });
  }
};
