import React from 'react';
import { Route, Switch } from 'react-router';

import App from './App';
import LandingPage from 'containers/LandingPage';
import AboutPage from 'containers/AboutPage';
import DeveloperPage from 'containers/DeveloperPage';
import JobsPage from 'containers/JobsPage';
import JobPage from 'containers/JobPage';
import TalentProgrammesPage from 'containers/TalentProgrammesPage';
import ContactPage from 'containers/ContactPage';
import NotFoundPage from 'containers/NotFoundPage';
import PolicyPage from 'containers/PolicyPage';

export default () => (
  <App>
    <Switch>
      <Route path="/" exact component={LandingPage} />
      <Route path="/about" exact component={AboutPage} />
      <Route path="/developer" exact component={DeveloperPage} />
      <Route path="/talent-programme" exact component={TalentProgrammesPage} />
      <Route path="/jobs" exact component={JobsPage} />
      <Route path="/jobs/:id" exact component={JobPage} />
      <Route path="/contact" exact component={ContactPage} />
      <Route path="/policy" exact component={PolicyPage} />
      <Route path="*" exact component={NotFoundPage} />
    </Switch>
  </App>
);
