import produce from 'immer';

import {
  GET_JOBS_REQUEST,
  GET_JOBS_SUCCESS,
  GET_JOBS_FAILURE,
} from './constants';

const initialState = {
  loading: false,
  error: '',
  jobs: [],
};

export default (baseState = initialState, action) =>
  produce(baseState, state => {
    switch (action.type) {
      case GET_JOBS_REQUEST:
        state.loading = true;
        state.error = '';
        break;
      case GET_JOBS_SUCCESS:
        state.loading = false;
        state.error = '';
        if (
          action.payload &&
          action.payload.every &&
          action.payload.every(item => item.type === 'job')
        ) {
          state.jobs = action.payload.sort((a, b) => {
            if (a.publishedAt > b.publishedAt) {
              return -1;
            } else if (a.publishedAt < b.publishedAt) {
              return 1;
            }
            return 0;
          });
        }
        break;
      case GET_JOBS_FAILURE:
        state.loading = false;
        state.error = action.error;
        break;
      default:
        break;
    }
  });
