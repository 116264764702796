import {
  GET_JOBS_REQUEST,
  GET_JOBS_SUCCESS,
  GET_JOBS_FAILURE,
} from './constants';

import PrimusHandler from 'utils/primus';

export const getJobs = () => dispatch => {
  try {
    dispatch({ type: GET_JOBS_REQUEST });
    PrimusHandler.sendRequest(
      {
        _handler: 'load-jobs',
        isReact: true,
      },
      GET_JOBS_SUCCESS,
    );
  } catch (error) {
    dispatch({ type: GET_JOBS_FAILURE, error });
  }
};
