import { applyMiddleware, compose, createStore, combineReducers } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import thunk from 'redux-thunk';

import primusReducer from 'utils/primusReducer';
import reducers from 'containers/reducers';

export const history = createBrowserHistory();

const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    primus: primusReducer,
    ...reducers,
  });

export default initialState => {
  const store = createStore(
    rootReducer(history),
    initialState,
    compose(applyMiddleware(thunk, routerMiddleware(history))),
  );
  return store;
};
