import React from 'react';

import { Wrapper } from 'components';
import BuilderComponent from 'components/BuilderComponent';

export default React.memo(() => (
  <Wrapper>
    <BuilderComponent
      entries={{
        sv: '9ccaa8248c554e3c81b0df87cc5e1c6b',
        en: '9ccaa8248c554e3c81b0df87cc5e1c6b',
      }}
    />
  </Wrapper>
));
