import styled, { css } from 'styled-components';

import { colors } from '../../';

export const DotContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

export const Dot = styled.div`
  width: 12px;
  height: 12px;
  cursor: pointer;
  border: 2px solid #888;
  border-radius: 50%;
  margin-right: 3px;

  :last-child {
    margin-right: 0;
  }

  :hover {
    border-color: ${colors.darkBlue};
    background: ${colors.darkBlue};
  }

  ${props =>
    props.isActive &&
    css`
      border-color: ${colors.darkBlue};
      background: ${colors.darkBlue};
    `}
`;

export const ArrowContainer = styled.div`
  display: flex;
  margin-top: 20px;
`;

export const Arrow = styled.div`
  color: #888;
  cursor: pointer;
  height: 20px;
  width: 36px;
  position: relative;

  :hover {
    color: ${colors.darkBlue};
  }

  :before {
    font-family: FontAwesome;
    font-size: 36px;
    line-height: 20px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    ${props =>
      props.left &&
      css`
        content: '\f177';
      `}
    ${props =>
      props.right &&
      css`
        content: '\f178';
      `}
  }

  ${props =>
    props.right &&
    css`
      margin-left: 10px;
    `}
`;

export const ItemsWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
`;

export const ItemsContainer = styled.div`
  display: flex;
  transition: transform 0.75s ease;

  > div {
    flex: 0 0 ${props => props.width || 60}%;

    @media (max-width: 667px) {
      flex: 1 0 100%;
    }
  }

  ${props => `transform: translateX(
    ${(100 - (props.width || 60)) / 2 -
      props.currentIndex * (props.width || 60)}%
  );

  @media (max-width: 667px) {
    transform: translateX(${props.currentIndex * -100}%);
  }`}
`;

export const Item = styled.div``;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  pointer-events: none;

  ${props =>
    props.numItems < 2 &&
    css`
      display: none;
    `}

  ${props =>
    props.center &&
    css`
      z-index: 1;
      background: linear-gradient(
        90deg,
        ${props => props.color || colors.black} 0%,
        rgba(255, 255, 255, 0) 20%,
        rgba(255, 255, 255, 0) 80%,
        ${props => props.color || colors.black} 100%
      );

      @media (max-width: 667px) {
        display: none;
      }
    `}

  ${props =>
    props.left &&
    css`
      background: linear-gradient(
        -90deg,
        rgba(0, 0, 0, 0),
        ${props => props.color || colors.black}
      );
    `}

  ${props =>
    props.right &&
    css`
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0),
        ${props => props.color || colors.black}
      );

      @media (max-width: 1024px) {
        background: linear-gradient(
          -90deg,
          rgba(0, 0, 0, 0),
          ${props => props.color || colors.black}
        );
      }
    `}
`;

export const UserQuote = styled.p`
  width: 70%;
  text-align: center;
  font-style: italic;

  @media (max-width: 667px) {
    width: 90%;
  }
`;
export const LinkName = styled.div`
  padding: 30px;
  font-size: 14px;

  p {
    margin-top: 0;
    color: ${colors.darkGray};
  }

  strong {
    color: ${colors.darkBlue};
  }

  em,
  span,
  li {
    color: ${colors.darkGray} !important;
  }

  a {
    color: ${colors.darkBlue};

    :hover {
      color: ${colors.darkGray2};
    }
  }

  img {
    width: 100%;
  }
`;
export const UserAvatar = styled.div`
  background: url(${props => props.image});
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  width: 90px;
  height: 90px;
  margin-bottom: 15px;
`;

export const UserName = styled.span`
  font-weight: bold;
  font-size: 14px;
`;

export const UserWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`;

export const UserContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  align-items: center;
`;
