import React from 'react';
import { faFacebookF, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

import {
  FooterWrapper,
  FooterContainer,
  CopyFooter,
  Logo,
  FooterSection,
  SectionHeader,
  SectionText,
  Icon,
  CopyText,
  RightCopyContainer,
  Link,
  SectionExtLink,
  CopyExtLink,
} from './styles';

import { useLanguage } from 'utils';
import * as strings from './strings';

export default () => {
  const [language] = useLanguage();
  return (
    <FooterWrapper>
      <FooterContainer>
        <Link to="/">
          <Logo />
        </Link>
        <FooterSection>
          <SectionHeader>{strings.QUICK_LINKS[language]}</SectionHeader>
          {strings.LINKS.map(link => (
            <Link key={link.to} to={{ pathname: link.to }} target={link.target}>
              {link[language]}
            </Link>
          ))}
          <br />
        </FooterSection>
        <FooterSection>
          <SectionHeader>{strings.GET_IN_TOUCH[language]}</SectionHeader>
          <SectionText>
            Drottninggatan 38
            <br />
            411 07 Göteborg
            <br />
            Sweden
          </SectionText>
          <SectionExtLink href="tel:+46313613191">
            +46 31 361 31 91
          </SectionExtLink>
          <SectionExtLink href="mailto:info@softwareskills.se">
            info@softwareskills.se
          </SectionExtLink>
        </FooterSection>
        <FooterSection isSocial>
          <a href="https://www.facebook.com/softwareskillsinternational/">
            <Icon icon={faFacebookF} />
          </a>
          <a href="https://www.linkedin.com/company/software-skills/">
            <Icon icon={faLinkedinIn} />
          </a>
        </FooterSection>
      </FooterContainer>
      <CopyFooter>
        <CopyText>{strings.ALL_RIGHTS[language]}</CopyText>
        <RightCopyContainer>
          <CopyExtLink href={`/policy`}>
            {strings.PRIVACY_POLICY[language]}
          </CopyExtLink>
        </RightCopyContainer>
      </CopyFooter>
    </FooterWrapper>
  );
};
