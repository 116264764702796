import styled, { css } from 'styled-components';

import {
  colors,
  Link as BaseLink,
  Input as BaseInput,
  Tag as BaseTag,
} from 'components';

export const JobGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;

export const JobCard = styled(BaseLink)`
  border: 1px solid ${colors.lightGray};
  background: ${colors.white};
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  width: 49%;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const JobTitle = styled.span`
  color: ${colors.darkBlue};
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 5px;
`;

export const JobCompany = styled.span`
  color: ${colors.lightGray2};
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 5px;
`;

export const JobContainer = styled.div`
  display: flex;
  width: 100%;

  ${props =>
    props.column &&
    css`
      flex-direction: column;
      flex: 1;
    `}
`;

export const DeadlineLabel = styled.span`
  color: ${colors.darkBlue};
  font-size: 12px;
  font-weight: bold;
`;

export const DeadlineValue = styled.span`
  color: ${colors.darkGray};
  font-size: 12px;
`;

export const JobDescription = styled.p`
  margin-top: 0;
  font-size: 14px;
  color: ${colors.darkGray};
  flex: 1;
`;

export const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const JobTag = styled(BaseTag)`
  margin-right: 5px;
  margin-bottom: 5px;

  :last-child {
    margin-right: 0;
  }
`;

export const PublishedContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const PublishedLabel = styled.div`
  color: ${colors.lightGray2};
  font-size: 13px;
`;

export const CompanyLogo = styled.div`
  background: url(${props => props.image});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 70px;
  height: 70px;
  margin-right: 20px;
`;

export const Hr = styled.hr`
  background: ${colors.lightGray2};
  border: 0;
  margin: 10px 0;
  height: 1px;
  width: 30px;
`;

export const FilterContainer = styled.div`
  width: 100%;
  background: ${colors.white};
  border-bottom: 1px solid ${colors.lightGray};
  display: flex;
  align-items: center;
  padding: 15px 20%;
  justify-content: space-between;

  @media (max-width: 1524px) {
    padding: 15px 20px;
  }

  @media (max-width: 1134px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const InputContainer = styled.div`
  display: flex;

  @media (max-width: 1134px) {
    justify-content: space-between;
    width: 100%;
  }

  @media (max-width: 667px) {
    flex-direction: column;
  }
`;

export const SelectContainer = styled.div`
  min-width: 250px;
  margin-right: 10px;

  @media (max-width: 1134px) {
    margin-bottom: 10px;
    width: 100%;
  }
`;

export const Input = styled(BaseInput)`
  width: 250px;
  margin-right: 10px;

  @media (max-width: 1134px) {
    margin-bottom: 10px;
    width: 100%;

    :last-child {
      margin-right: 0;
    }
  }
`;
