import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { faEnvelope, faPhone, faLink } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { toast } from 'react-toastify';
import Modal from '../../components/Modal';
import { LoadingLabel } from '../../components/BuilderComponent/styles';
import JobForm from './jobForm';
import { Wrapper, Button, Hr, Container, Checkbox } from 'components';
import { useActions, useLanguage } from 'utils';

import * as _ from 'underscore';

import {
  TopContainer,
  BottomContainer,
  Header,
  LeftContainer,
  FulltimeLabel,
  PublishedLabel,
  Content,
  RightSidebar,
  LeftContent,
  Image,
  SidebarHeader,
  SidebarSubHeader,
  SkillTag,
  LanguageContainer,
  LanguageDotContainer,
  LanguageDot,
  LanguageLevel,
  SidebarSection,
  SidebarLabel,
  Icon,
  A,
  IconContainer,
  IconWrapper,
} from './styles';
import * as actions from './actions';
import * as strings from './strings';

const getLanguageLevel = (level, language) => {
  switch (level) {
    case 1:
      return strings.LANGUAGELEVEL[1][language];
    case 2:
      return strings.LANGUAGELEVEL[2][language];
    case 3:
      return strings.LANGUAGELEVEL[3][language];
    case 4:
      return strings.LANGUAGELEVEL[4][language];
    default:
      return '';
  }
};

export default React.memo(({ match }) => {
  const { id } = match.params;
  const { loading, error, job, recruiter } = useSelector(state => state.job);
  const { getJob } = useActions(actions);
  const [gdpr, showGdpr] = useState(false);
  const [isAgree, setAgree] = useState(false);
  const [isFormOpen, setFormOpen] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showArchivedJobPopup, setShowArchivedJobPopup] = useState(false);
  const [copied, setCopied] = useState(false);
  const [language, setLanguage] = useLanguage();

  useEffect(() => {
    getJob(id);
  }, [getJob, id]);

  useEffect(() => {
    if (job?.archived) setShowArchivedJobPopup(true);
  }, [job]);

  useEffect(() => {
    if (error && !toast.isActive(error)) {
      toast.error(error, { toastId: error });
    }
  }, [error]);

  const successPopUp = () => (
    <Modal
      isOpen={showSuccess}
      toggle={() => {}}
      headerText={job.name ? `Application for ${job.name}` : 'Application'}
      body="Thank you for your application. We have emailed you, please check your inbox (or spam folder)."
      footer={
        <Button
          marginRight
          border
          primary
          onClick={() => setShowSuccess(false)}
        >
          Ok
        </Button>
      }
    />
  );

  return (
    <Wrapper>
      <TopContainer>
        {successPopUp()}
        {showArchivedJobPopup && (
          <Modal
            isOpen={showArchivedJobPopup}
            toggle={() => {}}
            headerText="Archived Job"
            body="This job is no longer available"
            footer={
              <Button
                marginRight
                border
                primary
                onClick={() => setShowArchivedJobPopup(false)}
              >
                Ok
              </Button>
            }
          />
        )}
        <Modal
          isOpen={gdpr}
          toggle={() => {
            showGdpr(!gdpr);
            setAgree(false);
          }}
          headerText={strings.PERSONALDATA[language]}
          body={
            <span>
              {strings.PRIVACYTEXT[language]}{' '}
              <a href="/policy">{strings.PRIVACYPOLICY[language]}</a>.
            </span>
          }
          footer={
            <Container noPadding agreeCheckBox>
              <Checkbox
                onChange={() => setAgree(!isAgree)}
                label={strings.AGREE[language]}
                right
              />
              <Button
                disabled={!isAgree}
                onClick={() => {
                  if (
                    isAgree &&
                    moment(job.deadlineAt).isSameOrAfter(moment())
                  ) {
                    showGdpr(false);
                    setAgree(false);
                    setFormOpen(true);
                  }
                }}
                noMargin
                border
                primary
              >
                {strings.NEXT[language]}
              </Button>
            </Container>
          }
        />
        <JobForm
          isOpen={isFormOpen}
          quickQuestions={job.form ? job.form : []}
          board={job.board}
          jobId={job._id}
          toggle={() => setFormOpen(!isFormOpen)}
          submitted={() => {
            setFormOpen(!isFormOpen);
            setShowSuccess(true);
          }}
        />
        {!loading ? (
          <>
            <LeftContainer>
              <Header>{job.name}</Header>
              {job.fulltime ? (
                <FulltimeLabel>{strings.FULLTIME[language]}</FulltimeLabel>
              ) : (
                <FulltimeLabel>{strings.PARTTIME[language]}</FulltimeLabel>
              )}
              <PublishedLabel>
                {strings.PUBLISHED[language]}:{' '}
                {moment(job.publishedAt).format('Do MMMM, YYYY')}
              </PublishedLabel>
              <PublishedLabel>
                {strings.APPLICATIONDEADLINE[language]}:{' '}
                {moment(job.deadlineAt).format('Do MMMM, YYYY')}
              </PublishedLabel>
            </LeftContainer>
            <Button
              onClick={() =>
                job.archived ? setShowArchivedJobPopup(true) : showGdpr(true)
              }
              MarginRight={75}
              noMargin
              border
              primary
            >
              {strings.APPLY[language]}
            </Button>
          </>
        ) : (
          <LoadingLabel>{strings.LOADING[language]}...</LoadingLabel>
        )}
      </TopContainer>
      <BottomContainer>
        <Content>
          {!loading ? (
            <>
              <LeftContent
                dangerouslySetInnerHTML={{ __html: job.description }}
              />
              <RightSidebar>
                <Image image={job.logo} />
                <SidebarHeader>{strings.REQUIREMENTS[language]}</SidebarHeader>
                <SidebarSection>
                  <SidebarSubHeader>
                    {strings.SKILLS[language]}
                  </SidebarSubHeader>
                  {job &&
                    job.skills &&
                    job.skills.map(skill => (
                      <SkillTag key={skill}>{skill}</SkillTag>
                    ))}
                </SidebarSection>
                {job.swedish && job.swedish > 0 ? (
                  <SidebarSection>
                    <LanguageContainer>
                      <SidebarSubHeader>
                        {strings.SWEDISH[language]}
                      </SidebarSubHeader>
                      <LanguageDotContainer>
                        {Array.from(Array(job.swedish), (_, i) => (
                          <LanguageDot key={i} />
                        ))}
                        <LanguageLevel>
                          {getLanguageLevel(job.swedish, language)}
                        </LanguageLevel>
                      </LanguageDotContainer>
                    </LanguageContainer>
                  </SidebarSection>
                ) : null}
                {job.english && job.english > 0 ? (
                  <SidebarSection>
                    <LanguageContainer>
                      <SidebarSubHeader>
                        {strings.ENGLISH[language]}
                      </SidebarSubHeader>
                      <LanguageDotContainer>
                        {Array.from(Array(job.english), (_, i) => (
                          <LanguageDot key={i} />
                        ))}
                        <LanguageLevel>
                          {getLanguageLevel(job.english, language)}
                        </LanguageLevel>
                      </LanguageDotContainer>
                    </LanguageContainer>
                  </SidebarSection>
                ) : null}
                <SidebarSection>
                  <SidebarSubHeader>{strings.CITY[language]}</SidebarSubHeader>
                  <SidebarLabel>{job.city}</SidebarLabel>
                </SidebarSection>
                <SidebarSection>
                  <SidebarSubHeader>
                    {strings.RECRUITER[language]}
                  </SidebarSubHeader>
                  <Image image={recruiter.avatar} />
                  <SidebarLabel center>{recruiter.name}</SidebarLabel>
                  {recruiter.email && (
                    <A extraMargin href={`mailto:${recruiter.email}`}>
                      <Icon icon={faEnvelope} />
                      <SidebarLabel>{recruiter.email}</SidebarLabel>
                    </A>
                  )}
                  {recruiter.phone && (
                    <A href={`tel:${recruiter.phone}`}>
                      <Icon icon={faPhone} />
                      <SidebarLabel>{recruiter.phone}</SidebarLabel>
                    </A>
                  )}
                </SidebarSection>
                <Hr light style={{ marginBottom: '10px' }} />
                <SidebarSection>
                  <SidebarLabel center>
                    {strings.SUBSCRIBETEXT[language]}{' '}
                  </SidebarLabel>
                  <A
                    href="http://eepurl.com/cg1yQb"
                    target="_blank"
                    style={{
                      marginTop: '10px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Button small border primary>
                      {strings.SUBSCRIBE[language]}
                    </Button>
                  </A>
                </SidebarSection>
                <Hr light />
                <SidebarSection>
                  <IconContainer>
                    <IconWrapper
                      icon={faFacebookF}
                      onClick={() => {
                        window.open(
                          `https://www.facebook.com/dialog/share?app_id=1478005009174555&display=popup&href=${window.location.href}`,
                          '_blank',
                          'width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0',
                        );
                      }}
                      tooltipText="Dela på Facebook"
                    />
                    <IconWrapper
                      icon={faTwitter}
                      onClick={() => {
                        window.open(
                          `http://twitter.com/share?url=${window.location.href}`,
                          '_blank',
                          'width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0',
                        );
                      }}
                      tooltipText="Dela på Twitter"
                    />
                    <IconWrapper
                      icon={faEnvelope}
                      onClick={() => {
                        window.open(
                          `mailto:?subject=${job.headline}&body=${window.location.href}`,
                        );
                      }}
                      tooltipText="Dela med email"
                    />
                    <IconWrapper
                      icon={faLink}
                      onClick={() => {
                        let dummy = document.createElement('input'),
                          text = window.location.href;
                        document.body.appendChild(dummy);
                        dummy.value = text;
                        dummy.select();
                        let res = document.execCommand('copy');
                        if (res) setCopied(true);
                        document.body.removeChild(dummy);
                      }}
                      tooltipText={copied ? 'Copied!' : 'Kopiera URL'}
                    />
                  </IconContainer>
                </SidebarSection>
              </RightSidebar>
            </>
          ) : (
            <LoadingLabel>Laddar...</LoadingLabel>
          )}
        </Content>
      </BottomContainer>
    </Wrapper>
  );
});
