import produce from 'immer';

import { STORE_PRIMUS_RESPONSE } from './primus';

const initialState = {
  set_response: null,
};

export default (baseState = initialState, action) =>
  produce(baseState, state => {
    switch (action.type) {
      case STORE_PRIMUS_RESPONSE:
        if (action.payload && action.payload._handler) {
          state[action.payload._handler] = action.payload;
        } else {
          state.set_response = action.payload;
        }
        break;
      default:
        break;
    }
  });
