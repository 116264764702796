import React from 'react';
import { connect } from 'react-redux';
import Websocket from 'react-websocket';
import { toast } from 'react-toastify';

export const STORE_PRIMUS_RESPONSE = 'STORE_PRIMUS_RESPONSE';

let self;
class PrimusHandler extends React.Component {
  state = {
    isConnected: false,
  };

  static sendRequest = (data, actionType) => {
    self.actionType = actionType;
    let interval = setInterval(() => {
      if (self.state.isConnected) {
        clearInterval(interval);
        self.refWebSocket.sendMessage(JSON.stringify(data));
      }
    }, 200);
  };

  handleData = data => {
    if (String(data)?.includes('primus::ping::'))
      return self.refWebSocket.sendMessage(
        String(data).replace('ping', 'pong'),
      );
    this.props.storePrimusResponse(JSON.parse(data), this.actionType);
  };

  handleClose = () => {
    toast.error('Websocket could not connect');
    this.setState({
      isConnected: false,
    });
  };

  handleOpen = () => {
    this.setState({
      isConnected: true,
    });
    setInterval(() => {
      if (this.state.isConnected) {
        this.refWebSocket.sendMessage(JSON.stringify({ heartbeat: 'react' }));
      }
    }, 5000);
  };

  render = () => {
    self = this;
    return (
      <Websocket
        url={`${process.env.REACT_APP_WEBSOCKET_API}/live`}
        onMessage={this.handleData}
        onOpen={this.handleOpen}
        onClose={this.handleClose}
        debug={true}
        ref={Websocket => {
          this.refWebSocket = Websocket;
        }}
      />
    );
  };
}

const mapDispatchToProps = dispatch => ({
  storePrimusResponse: (payload, actionType) =>
    dispatch({ type: actionType || STORE_PRIMUS_RESPONSE, payload }),
});

export default connect(null, mapDispatchToProps)(PrimusHandler);
