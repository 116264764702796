export const HERO_TITLE = {
  sv: 'Jobbannonser',
  en: 'Job ads',
};

export const PLACEHOLDER_CITY = {
  sv: 'Stad',
  en: 'City',
};

export const PLACEHOLDER_SEARCH = {
  sv: 'Sök',
  en: 'Search',
};

export const PUBLISHED = {
  sv: 'Publicerad',
  en: 'Published',
};

export const ADS_FOUND = {
  sv: 'annonser funna',
  en: 'ads found',
};

export const LOADING = {
  sv: 'Laddar',
  en: 'Loading',
};
