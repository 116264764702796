import styled from 'styled-components';

import { Images } from '../../utils';

const FLAG_HEIGHT = '23px';
const FLAG_WIDTH = '30px';

export const FlagIcon = styled.li`
  background: url(${props => props.icon && Images[props.icon + `Flag`]});
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
  width: ${FLAG_WIDTH};
  height: ${FLAG_HEIGHT};
  list-style: none;
`;

export const MenuContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-bottom: 0px;
  @media (max-width: 1100px) {
    margin-bottom: 5px;
    margin-left: 0px;
  }
`;

export const MenuList = styled.ul`
  position: relative;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  top: 0px;
  @media (max-width: 1100px) {
    flex-direction: row;
  }
  @media (min-width: 1101px) {
    top: 11.5px;
    /* half of FLAG_HEIGHT*/
  }
`;
