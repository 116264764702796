import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Link as BaseLink, A as BaseA, colors } from 'components';
import LogoImage from 'assets/logo-white.svg';

import { FOOTER_HEIGHT } from 'utils';

export const FooterWrapper = styled.div`
  width: 100%;
  height: ${FOOTER_HEIGHT}px;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    height: auto;
  }
`;

export const FooterContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${colors.darkBlue};
  padding: 0 18%;

  @media (max-width: 1411px) {
    padding: 0 50px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 20px;
    align-items: flex-start;

    div {
      padding: 0;
    }
  }
`;

export const CopyFooter = styled.div`
  height: 40px;
  width: 100%;
  background: ${colors.white};
  padding: 20px 18%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;

  @media (max-width: 1366px) {
    padding: 20px 20px;
  }

  @media (max-width: 667px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 45px;
    padding: 0 15px;
  }
`;

export const Logo = styled.img.attrs({ src: LogoImage })`
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  height: 50px;
`;

export const FooterSection = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${colors.white};
  flex-shrink: 0;

  @media (max-width: 768px) {
    height: auto;
  }

  ${props =>
    props.isSocial &&
    css`
      width: 70px;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    `}
`;

export const Icon = styled(FontAwesomeIcon)`
  color: ${colors.white};
  cursor: pointer;
  font-size: 26px;
  :hover {
    color: ${colors.darkGray2};
  }
`;

export const SectionHeader = styled.span`
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 20px;
`;

export const SectionText = styled.span`
  font-size: 13px;
  text-transform: uppercase;
  margin-bottom: 20px;
`;

export const Link = styled(BaseLink)`
  font-size: 13px;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: ${colors.white};
  :hover {
    color: ${colors.darkGray2};
  }
`;

export const CopyExtLink = styled(BaseA)`
  color: ${colors.lightGray2};
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 1px;
  margin: auto;

  :hover {
    color: ${colors.darkGray2};
    text-decoration: none;
  }
`;

export const CopyLink = styled(Link)`
  color: ${colors.lightGray2};
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 1px;
  margin: auto;
  :hover {
    color: ${colors.darkGray2};
  }
`;

export const SectionExtLink = styled.a`
  font-size: 13px;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 10px;
  color: ${colors.white};
  :hover {
    color: ${colors.darkGray2};
    text-decoration: none;
  }
`;

export const RightCopyContainer = styled.div`
  display: flex;
`;

export const CopyText = styled.span`
  color: ${colors.lightGray2};
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 1px;
  white-space: pre;
`;
