import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import { Link as BaseLink, colors } from 'components';
import LogoImage from 'assets/logo-blue.svg';

export const HeaderWrapper = styled.div`
  background: ${colors.white};
  position: fixed;
  top: 0;
  width: 100vw;
  height: 80px;
  transition: all 0.25s ease-in-out;
  z-index: 4;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);

  @media (max-width: 1100px) {
    ${props =>
      props.isOpened &&
      css`
        height: 60px;
      `}
  }

  ${props =>
    props.isScrolled &&
    css`
      height: 60px;
    `};
`;

export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 15%;

  @media (max-width: 1600px) {
    padding: 0 20px;
  }
`;

export const NavigationContainer = styled.div`
  color: ${colors.darkBlue};
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  display: flex;
  width: 100%;
  align-items: center;
  transition: all 0.25s ease-in-out;

  @media (max-width: 1100px) {
    flex-direction: column-reverse;
    padding: 10px 15px;
    align-items: flex-start;
    position: absolute;
    background: ${colors.white};
    top: 0;
    left: 0;
    right: 0;
    transform: translate(0, -100%);
    width: 100%;
    box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.5);

    ${props =>
      props.isOpened &&
      css`
        top: 60px;
        transform: translate(0, 0);
      `}
  }
`;

export const Link = styled(BaseLink)`
  font-size: 14px;
  letter-spacing: 1px;
  color: ${colors.darkBlue};

  :hover {
    color: ${colors.darkGray2};
  }

  @media (max-width: 1100px) {
    margin-bottom: 5px;
  }
`;

export const LinkSeparator = styled.div`
  background: ${colors.darkBlue};
  width: 1px;
  height: 15px;
  margin: 0 20px;

  @media (max-width: 1100px) {
    display: none;
  }
`;

export const Logo = styled.img.attrs({ src: LogoImage })`
  flex: 1 1 250px;
  height: 55px;
  transition: all 0.25s ease-in-out;

  ${props =>
    (props.isScrolled || props.isOpened) &&
    css`
      height: 45px;
    `}
`;

export const MenuIcon = styled(FontAwesomeIcon).attrs({ icon: faBars })`
  display: none;
  cursor: pointer;
  font-size: 32px;
  color: ${colors.darkBlue};

  @media (max-width: 1100px) {
    display: block;
  }
`;

export const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  @media (max-width: 1100px) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
`;
