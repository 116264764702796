import React from 'react';
import Helmet from 'react-helmet';

import { Wrapper } from 'components';
import BuilderComponent from 'components/BuilderComponent';

export default React.memo(() => (
  <Wrapper>
    <Helmet>
      <title>Software Skills | IT-rekrytering i Göteborg</title>
      <meta
        name="description"
        content="Letar du efter utvecklare eller andra IT-specialister i Göteborg? IT-rekryterarna på Software Skills bygger upp din techavdelning från insidan."
      />
    </Helmet>
    <BuilderComponent
      entries={{
        sv: 'bd81e12a283843efb4a71b3afeaf2305',
        en: 'ec79e7d36d674840b086982aba0a7580',
      }}
    />
  </Wrapper>
));
