import { useState, useEffect, useContext, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LanguageContext } from '../components/LanguageContext';

export * from './images';

export const FOOTER_HEIGHT = 280;

export const useIsScrolled = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  const getIsScrolled = () => window.pageYOffset > 0;

  useEffect(() => {
    setIsScrolled(getIsScrolled());
    const onScroll = () => {
      setIsScrolled(getIsScrolled());
    };

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);
  return isScrolled;
};

export const useActions = actions => {
  const dispatch = useDispatch();
  return useMemo(() => {
    if (Array.isArray(actions)) {
      return actions.map(a => bindActionCreators(a, dispatch));
    }
    return bindActionCreators(actions, dispatch);
  }, [dispatch, actions]);
};

// eslint-disable-next-line
export const emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
export const nameRegex = /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/;

export const getBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

export const getBase64FromUrl = url => {
  return new Promise((resolve, reject) => {
    var request = new XMLHttpRequest();
    request.open('GET', url, true);
    request.responseType = 'blob';
    request.onload = function() {
      var reader = new FileReader();
      reader.readAsDataURL(request.response);
      reader.onload = e => {
        resolve(e.target.result);
      };
      reader.onerror = error => reject(error);
    };
    request.send();
  });
};

export const useLanguage = () => useContext(LanguageContext);
