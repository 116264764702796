import produce from 'immer';

import {
  GET_JOB_REQUEST,
  GET_JOB_SUCCESS,
  GET_JOB_FAILURE,
  UPDATE_CANDIDATE_REQUEST,
  UPDATE_CANDIDATE_SUCCESS,
  UPDATE_CANDIDATE_FAILURE,
} from './constants';
import { emailRegex } from 'utils';

const initialState = {
  loading: false,
  error: '',
  applyJobError: '',
  job: {},
  recruiter: {},
  updateResponse: '',
};

export default (baseState = initialState, action) =>
  produce(baseState, state => {
    switch (action.type) {
      case GET_JOB_REQUEST:
        state.loading = true;
        state.error = '';
        break;
      case GET_JOB_SUCCESS:
        state.loading = false;
        state.error = '';
        if (
          action.payload &&
          typeof action.payload === 'object' &&
          action.payload.type === 'job'
        ) {
          state.job = action.payload;
          state.job.description = state.job.description.replace(
            emailRegex,
            `<a href="mailto:$1">$1</a>`,
          );
        }
        if (
          action.payload &&
          action.payload.some &&
          action.payload.some(item => item.type === 'account')
        ) {
          if (state.job && state.job.account) {
            state.recruiter = action.payload.find(
              account => account._id === state.job.account,
            );
          }
        }
        break;
      case GET_JOB_FAILURE:
        state.loading = false;
        state.error = action.error;
        break;
      case UPDATE_CANDIDATE_REQUEST:
        state.loading = true;
        state.applyJobError = '';
        break;
      case UPDATE_CANDIDATE_SUCCESS:
        state.success = true;
        state.loading = false;
        state.updateResponse = action.payload;
        state.applyJobError = '';
        break;
      case UPDATE_CANDIDATE_FAILURE:
        state.loading = false;
        state.applyJobError = action.error;
        break;
      default:
        break;
    }
  });
