import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { toast } from 'react-toastify';
import Helmet from 'react-helmet';

import { Wrapper, Hero, HeroTitle, Container, Select } from 'components';
import JobsHeader from 'assets/jobs-header.jpg';
import { useActions, useLanguage } from 'utils';
import * as actions from './actions';
import { LoadingLabel } from '../../components/BuilderComponent/styles';
import {
  JobCard,
  PublishedLabel,
  JobGrid,
  JobContainer,
  CompanyLogo,
  JobTitle,
  DeadlineLabel,
  DeadlineValue,
  Hr,
  JobDescription,
  TagsContainer,
  JobTag,
  PublishedContainer,
  FilterContainer,
  SelectContainer,
  Input,
  InputContainer,
} from './styles';

import * as strings from './strings';

const cityFilter = selectedCity => object =>
  selectedCity ? object.city === selectedCity : true;

const skillFilter = skillQuery => object =>
  skillQuery
    ? object.skills.some(
        skill =>
          skill && skill.toLowerCase().indexOf(skillQuery.toLowerCase()) > -1,
      )
    : true;

const searchFilter = searchQuery => object =>
  Object.values(object).some(
    field =>
      field &&
      String(field)
        .toLowerCase()
        .indexOf(searchQuery.toLowerCase()) > -1,
  );

export default React.memo(() => {
  const { loading, error, jobs } = useSelector(state => state.jobs);
  const { getJobs } = useActions(actions);
  const [selectedCity, setSelectedCity] = useState(null);
  const [skillQuery, setSkillQuery] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [items, setItems] = useState(jobs.slice());
  const [language, setLanguage] = useLanguage();

  useEffect(() => {
    getJobs();
  }, [getJobs]);

  useEffect(() => {
    if (error && !toast.isActive(error)) {
      toast.error(error, { toastId: error });
    }
  }, [error]);

  useEffect(() => {
    setItems(
      jobs
        .filter(cityFilter(selectedCity))
        .filter(skillFilter(skillQuery))
        .filter(searchFilter(searchQuery)),
    );
  }, [selectedCity, skillQuery, searchQuery, jobs]);

  const options = Object.keys(
    jobs.reduce((acc, curr) => {
      acc[curr.city] = true;
      return acc;
    }, {}),
  )
    .filter(city => {
      return city.length;
    })
    .map(city => ({ value: city, label: city }));

  return (
    <Wrapper noMargin>
      <Helmet>
        <title>Jobbannonser</title>
        <meta
          name="description"
          content="IT and software development jobs in Gothenburg, Sweden | Apply for the latest IT and programming job opportunities in the Gothenburg tech industry."
        />
      </Helmet>
      <Hero overlay="top" image={JobsHeader}>
        <HeroTitle>{strings.HERO_TITLE[language]}</HeroTitle>
      </Hero>
      <FilterContainer>
        <InputContainer>
          <SelectContainer>
            <Select
              isClearable
              isSearchable
              placeholder={strings.PLACEHOLDER_CITY[language]}
              value={options.find(option => option.value === selectedCity)}
              onChange={option => setSelectedCity(option && option.value)}
              options={options}
            />
          </SelectContainer>
          <Input
            placeholder="Skills"
            value={skillQuery}
            onChange={e => setSkillQuery(e.target.value)}
          />
          <Input
            placeholder={strings.PLACEHOLDER_SEARCH[language]}
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
          />
        </InputContainer>
        <JobTitle>{`${items.length} ${strings.ADS_FOUND[language]}`}</JobTitle>
      </FilterContainer>
      <Container>
        <JobGrid>
          {!loading ? (
            items.map(job => (
              <JobCard key={job._id} to={`/jobs/${job._id}`}>
                <CompanyLogo image={job.logo} />
                <JobContainer column>
                  <PublishedContainer>
                    <PublishedLabel>
                      {strings.PUBLISHED[language]}:{' '}
                      {moment(job.publishedAt).format('DD-MM-YYYY')}
                    </PublishedLabel>
                  </PublishedContainer>
                  <JobTitle>{job.name}</JobTitle>
                  <span>
                    <DeadlineLabel>Deadline: </DeadlineLabel>
                    <DeadlineValue>
                      {moment(job.deadlineAt).format('DD-MM-YYYY')}
                    </DeadlineValue>
                  </span>
                  <Hr />
                  <JobDescription>{job.headline}</JobDescription>
                  <TagsContainer>
                    {job.skills.map(skill => (
                      <JobTag key={skill}>{skill}</JobTag>
                    ))}
                  </TagsContainer>
                </JobContainer>
              </JobCard>
            ))
          ) : (
            <LoadingLabel>{strings.LOADING[language]}</LoadingLabel>
          )}
        </JobGrid>
      </Container>
    </Wrapper>
  );
});
