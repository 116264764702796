import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer, Zoom } from 'react-toastify';
import { LanguageProvider } from './components/LanguageContext';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-quill/dist/quill.snow.css';
import { detect } from 'detect-browser';

import Header from 'components/Header';
import Footer from 'components/Footer';
import PrimusHandler from 'utils/primus';
const browser = detect();

export default ({ children, ...props }) => {
  const {
    location: { pathname },
  } = useSelector(state => state.router);

  const [isEdgeOrIEBrowserModal, setIsEdgeOrIEBrowserModal] = useState(false);

  useEffect(() => {
    if (browser.name === 'ie' || browser.name === 'edge') {
      // This condition is to check whether the browser is IE or Edge
      setIsEdgeOrIEBrowserModal(true);
    }
  }, []);

  const toggle = () => {
    setIsEdgeOrIEBrowserModal(false);
  };

  const renderModal = () => {
    return (
      <Modal className="detailsPopUp" isOpen={isEdgeOrIEBrowserModal}>
        <ModalHeader cssModule={{ 'modal-title': 'w-100 ' }}>
          <span
            style={{
              width: '100%',
              fontSize: 16,
              color: '#616161',
              paddingTop: '20px',
            }}
          >
            We have detected you are using Edge or Internet Explorer. To have a
            better user experience we recommend using Chrome, Safari or Firefox
            as your web browser.
          </span>
        </ModalHeader>
        <ModalBody>
          <div className="gdpr-checkbox-container">
            <div></div>
            <button
              type="submit"
              onClick={() => toggle()}
              className="btn btn-primary submit-button"
            >
              Cancel
            </button>
          </div>
        </ModalBody>
      </Modal>
    );
  };

  return (
    <>
      <LanguageProvider>
        {renderModal()}
        <Header pathname={pathname} />
        {children}
        <Footer />
        <ToastContainer
          transition={Zoom}
          position="bottom-right"
          autoClose={2000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        <PrimusHandler {...props} />
      </LanguageProvider>
    </>
  );
};
