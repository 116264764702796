export const LINKS = [
  {
    to: '/',
    sv: 'IT-rekrytering',
    en: 'IT Recruitment',
  },
  {
    to: '/talent-programme',
    sv: 'Talangprogram',
    en: 'Talent programmes',
  },
  {
    to: '/developer',
    target: '',
    sv: 'Jobba som utvecklare',
    en: 'Work in the IT industry',
  },
  {
    to: 'https://jobs.softwareskills.se',
    target: '_blank',
    sv: 'Jobbannonser',
    en: 'Job ads',
  },
  {
    to: '/contact',
    target: '',
    sv: 'Kontakta våra IT-rekryterare',
    en: 'Contact our IT recruiters',
  },
];
