import styled from 'styled-components';

import { colors } from 'components';

export const TooltipContainer = styled.div`
  position: absolute;
  background: ${props => (props.light ? colors.lightGray : colors.darkGray)};
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  top: -20%;
  white-space: nowrap;
  transform: translateY(-100%);
  z-index: 5;
  color: ${props => (props.light ? colors.darkGray : colors.white)};
  opacity: 0.95;
  pointer-events: none;

  :after {
    content: '';
    position: absolute;
    bottom: 1px;
    left: 50%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: ${props =>
      props.light ? colors.lightGray : colors.darkGray};
    border-bottom: 0;
    margin-left: -10px;
    margin-bottom: -10px;
  }
`;
