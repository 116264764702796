import React from 'react';

import { Wrapper } from 'components';
import BuilderComponent from 'components/BuilderComponent';

export default React.memo(() => (
  <Wrapper>
    <BuilderComponent
      entries={{
        sv: '040042d4a1eb4c2e9817b8dd89bd3621',
        en: 'ec79e7d36d674840b086982aba0a7580',
      }}
    />
  </Wrapper>
));
