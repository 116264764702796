import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { useIsScrolled, useLanguage, useActions } from 'utils';
import {
  HeaderWrapper,
  HeaderContainer,
  Logo,
  NavigationContainer,
  Link,
  MenuIcon,
  LinkContainer,
  LinkSeparator,
} from './styles';
import FlagDropdown from '../FlagDropdown';

import { LINKS } from './strings';

export default ({ pathname }) => {
  const [isOpened, setIsOpened] = useState(false);
  const isScrolled = useIsScrolled();
  const [language, setLanguage] = useLanguage();

  // Automatically closes menu if pathname or language changes (only happens on smaller resolutions)
  useEffect(() => {
    setIsOpened(false);
  }, [pathname, language]);

  return (
    <HeaderWrapper isScrolled={isScrolled} isOpened={isOpened}>
      <HeaderContainer>
        <RouterLink to="/">
          <Logo isScrolled={isScrolled} isOpened={isOpened} />
        </RouterLink>
        <MenuIcon onClick={() => setIsOpened(!isOpened)} />
        <NavigationContainer isOpened={isOpened}>
          <LinkContainer>
            {LINKS.map((link, index) => (
              <React.Fragment key={link.to}>
                <Link
                  key={link.to}
                  to={{ pathname: link.to }}
                  target={link.target}
                >
                  {link[language]}
                </Link>
                {index !== LINKS.length - 1 && <LinkSeparator />}
              </React.Fragment>
            ))}
          </LinkContainer>
          <FlagDropdown language={language} setLanguage={setLanguage} />
        </NavigationContainer>
      </HeaderContainer>
    </HeaderWrapper>
  );
};
