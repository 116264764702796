import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { colors } from 'components';

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Container = styled.div``;

export const TitleContainer = styled.div`
  width: 100%;
  padding: 1em 0.5em;
  border: #ccc solid 1px;
  border-radius: 5px;
  cursor: pointer;
  background: ${colors.darkBlue};
  color: ${colors.white};

  @media (min-width: 769px) {
    background: ${colors.white};
    color: ${colors.black};
    ${props =>
      props.active == true &&
      css`
        background: ${colors.darkBlue};
        color: ${colors.white};

        :hover {
          background: ${colors.darkBlue}!important;
        }
      `};

    :hover {
      background: ${colors.lightGray};
    }
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  padding: 1em 0.5em;
  border: #ccc solid 1px;
  border-radius: 5px;
  background: ${colors.white};
  display: none;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    display: block;
  }
`;
export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`;
export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  text-align: left;
  padding-left: 30px;
  @media (max-width: 768px) {
    margin-bottom: 20px;
    display: none;
  }
`;
