import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

import { colors, Tag as BaseTag, A as BaseA } from 'components';
import Tooltip from 'components/Tooltip';

export const TopContainer = styled.div`
  background: ${colors.lightBlue};
  height: 400px;
  width: 100%;
  padding: 0 15%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.lightGray};

  @media (max-width: 1411px) {
    padding: 0 40px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
`;

export const A = styled(BaseA)`
  display: flex;
  align-items: center;
  margin-top: 5px;

  :hover {
    svg,
    span {
      color: ${colors.darkGray2};
    }
  }

  ${props =>
    props.extraMargin &&
    css`
      margin-top: 10px;
    `}
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

export const FulltimeLabel = styled.div`
  background: ${colors.lightOrange};
  color: ${colors.darkOrange};
  margin-right: auto;
  padding: 0 5px;
  font-size: 12px;
`;

export const SidebarSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    align-items: center;
    :last-child {
      margin-bottom: 0;
    }
  }
`;

export const SkillTag = styled(BaseTag)`
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 5px;
  margin-right: auto;
  padding: 3px 10px;

  :last-child {
    margin-bottom: 0;
  }

  @media (max-width: 768px) {
    margin-left: auto;
  }
`;

export const LanguageContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    align-items: center;
  }
`;

export const LanguageDotContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const LanguageLevel = styled.span`
  font-size: 14px;
  color: ${colors.darkBlue};
  font-style: italic;
  margin-left: 5px;
`;

export const LanguageDot = styled(FontAwesomeIcon).attrs({ icon: faCircle })`
  font-size: 12px;
  margin-right: 5px;
  color: ${colors.darkBlue};
`;

export const PublishedLabel = styled.span`
  color: ${colors.darkGray};
  font-size: 14px;
  margin-top: 5px;
`;

export const BottomContainer = styled.div`
  width: 100%;
  padding: 0 15%;
  position: relative;

  @media (max-width: 1411px) {
    padding: 0 40px;
  }
`;

export const Content = styled.div`
  background: ${colors.white};
  border: 1px solid ${colors.lightGray};
  border-radius: 3px;
  position: relative;
  top: -50px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

export const LeftContent = styled.div`
  padding: 30px;
  font-size: 14px;

  p {
    margin-top: 0;
    color: ${colors.darkGray};
  }

  strong {
    color: ${colors.darkBlue};
  }

  em,
  span,
  li {
    color: ${colors.darkGray} !important;
  }

  a {
    color: ${colors.darkBlue};

    :hover {
      color: ${colors.darkGray2};
    }
  }

  img {
    width: 100%;
  }
`;

export const SidebarLabel = styled.span`
  color: ${colors.darkGray};
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;

  ${props =>
    props.center &&
    css`
      text-align: center;
    `};
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const IconWrapper = ({ icon, onClick, tooltipText }) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <IconSquare
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}
    >
      {isHovered && <Tooltip>{tooltipText}</Tooltip>}
      <Icon icon={icon} />
    </IconSquare>
  );
};

const IconSquare = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  width: 35px;
  height: 35px;
  border: 1px solid ${colors.lightGray};
  border-radius: 3px;
  margin-right: 5px;
  margin-top: 10px;
  position: relative;

  :last-child {
    margin-right: 0;
  }

  svg {
    margin: 0;
  }

  :hover {
    border-color: ${colors.lightGray2};
    svg {
      color: ${colors.darkGray2};
    }
  }

  ${props =>
    props.onClick &&
    css`
      cursor: pointer;
    `}
`;

export const Icon = styled(FontAwesomeIcon)`
  color: ${colors.darkGray};
  margin-right: 10px;
`;

export const RightSidebar = styled.div`
  min-width: 300px;
  border-left: 1px solid ${colors.lightGray};
  padding: 20px;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    border-bottom: 1px solid ${colors.lightGray};
    border-left: 0;
    align-items: center;
  }
`;

export const SidebarHeader = styled.h2`
  color: ${colors.darkGray2};
  text-transform: uppercase;
  margin: 0 0 10px;
`;

export const SidebarSubHeader = styled.h5`
  color: ${colors.darkGray};
  margin: 0 0 10px;
`;

export const Header = styled.h1`
  font-size: 42px;
  color: ${colors.darkBlue};
  margin-bottom: 10px;
  margin-top: 0;
`;

export const Image = styled.div`
  background: url(${props => props.image});
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 200px;
  width: 100%;
`;
