import React from 'react';

import { Wrapper, Container, Button, SectionHeader, Link } from 'components';

export default React.memo(() => {
  return (
    <Wrapper>
      <Container column center>
        <SectionHeader bold dark>
          404
        </SectionHeader>
        <SectionHeader dark>Sidan hittades inte</SectionHeader>
        <Link to="/">
          <Button primary border>
            Gå till startsidan
          </Button>
        </Link>
      </Container>
    </Wrapper>
  );
});
