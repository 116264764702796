import React from 'react';
import { builder, BuilderComponent } from '@builder.io/react';
import '../BuilderAccordion';

import { Container, LoadingLabel } from './styles';
import { useLanguage } from '../../utils';
import * as strings from './strings';

// The following imports are to expose them to builder.io
/* eslint-disable no-unused-vars */
// import { BuilderSVG } from 'user/components/CurvedContainer';
import { BuilderCarousel } from './Carousel';
/* eslint-enable no-unused-vars */

builder.init('ec17360b17744903a233e7aa739d27b7');

export default ({ entries, entry, key, ...props }) => {
  const [language] = useLanguage();
  return (
    <BuilderComponent
      name="sws-page"
      key={key || language}
      entry={entries[language] || 'sv'}
      {...props}
    >
      <Container>
        <LoadingLabel>{strings.loadingLabel[language]}</LoadingLabel>
      </Container>
    </BuilderComponent>
  );
};
