import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { colors } from '../index';

export default props => {
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle}>
      <ModalHeader style={styles.header}>{props.headerText}</ModalHeader>
      <ModalBody style={styles.body}>{props.body}</ModalBody>
      <ModalFooter>{props.footer}</ModalFooter>
    </Modal>
  );
};

const styles = {
  header: {
    color: colors.darkBlue,
  },
  body: {
    padding: '30px',
    wordSpacing: '2px',
    lineHeight: '1.8',
    color: colors.darkGray,
  },
};
