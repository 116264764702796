import React from 'react';
import Helmet from 'react-helmet';

import { Wrapper } from 'components';
import BuilderComponent from 'components/BuilderComponent';

export default React.memo(() => (
  <Wrapper>
    <Helmet>
      <title>Jobba som utvecklare</title>
      <meta
        name="description"
        content="How do you get a developer job in Gothenburg, Sweden? | Kickstart your career in the Gothenburg tech industry with the help of our IT recruiters."
      />
    </Helmet>
    <BuilderComponent
      entries={{
        sv: 'b06c00159e3f4a829b97d7ec988fdfe4',
        en: '70ccf93efe2347caaaa12be2326b8954',
      }}
    />
  </Wrapper>
));
