export const LANGUAGELEVEL = {
  1: {
    sv: 'Nybörjare',
    en: 'Beginner',
  },
  2: {
    sv: 'Medel',
    en: 'Intermediate',
  },
  3: {
    sv: 'Kunnig',
    en: 'Knowledgeable',
  },
  4: {
    sv: 'Flytande',
    en: 'Fluent',
  },
};

export const PUBLISHED = {
  sv: 'Publicerad',
  en: 'Published',
};

export const LOADING = {
  sv: 'Laddar',
  en: 'Loading',
};

export const APPLY = {
  sv: 'Ansök',
  en: 'Apply',
};

export const FULLTIME = {
  sv: 'Heltid',
  en: 'Full-time',
};
export const PARTTIME = {
  sv: 'Deltid',
  en: 'Part-time',
};

export const CITY = {
  sv: 'Stad',
  en: 'City',
};
export const RECRUITER = {
  sv: 'Rekryterare',
  en: 'Recruiter',
};

export const ENGLISH = {
  sv: 'Engelska',
  en: 'English',
};

export const SWEDISH = {
  sv: 'Svenska',
  en: 'Swedish',
};

export const SUBSCRIBE = {
  sv: 'Prenumerera',
  en: 'Subscribe',
};
export const SUBSCRIBETEXT = {
  sv: 'Få emailuppdateringar för nya positioner hos Software Skills',
  en: 'Get email updates for new positions at Software Skills',
};

export const APPLICATIONDEADLINE = {
  sv: 'Sista ansökningsdag',
  en: 'Application deadline',
};

export const REQUIREMENTS = {
  sv: 'Krav',
  en: 'Requirements',
};

export const SKILLS = {
  sv: 'Färdigheter',
  en: 'Skills',
};

export const NEXT = {
  sv: 'Nästa',
  en: 'Next',
};

export const AGREE = {
  sv: 'Jag godkänner',
  en: 'I agree',
};

export const PRIVACYPOLICY = {
  sv: 'Integritetspolicy',
  en: 'Privacy policy',
};

export const PERSONALDATA = {
  sv: 'Personlig data',
  en: 'Personal data',
};

export const PRIVACYTEXT = {
  sv:
    'Genom att klicka i rutan godkänner du att dina personuppgifter behandlas i enlighet med vår',
  en:
    'By checking the box, you agree your personal information will be treated in accordance with our',
};
