import React from 'react';
import { Builder } from '@builder.io/react';
import { Nav } from 'reactstrap';
import {
  TitleContainer,
  RightContainer,
  LeftContainer,
  Row,
  MenuIcon,
  ContentContainer,
  Container,
} from './styles';

class PolicyAccordions extends React.Component {
  state = {
    currentContent: this.props.accordion
      ? this.props.accordion[0].content
      : null,
    currentTitle: 0,
  };

  setContent = (e, card) => {
    let event = e.target.dataset.event;
    this.setState({
      currentTitle: Number(event),
      currentContent: card.content,
    });
  };

  render() {
    const cards = this.props.accordion;
    return (
      <Row>
        <LeftContainer>
          <Nav vertical>
            {cards
              ? cards.map((card, index) => {
                  return (
                    <Container>
                      <TitleContainer
                        key={index}
                        data-event={index}
                        onClick={event => this.setContent(event, card)}
                        active={
                          this.state.currentTitle === index ? true : false
                        }
                      >
                        <span data-event={index}>{card.title}</span>
                      </TitleContainer>
                      <ContentContainer>
                        <Container
                          dangerouslySetInnerHTML={{ __html: card.content }}
                        />
                      </ContentContainer>
                    </Container>
                  );
                })
              : null}
          </Nav>
        </LeftContainer>
        <RightContainer>
          <Container
            dangerouslySetInnerHTML={{ __html: this.state.currentContent }}
          />
        </RightContainer>
      </Row>
    );
  }
}

Builder.registerComponent(PolicyAccordions, {
  name: 'Accordion',
  inputs: [
    {
      name: 'accordion',
      type: 'list',
      defaultValue: [{ reviewText: 'hello' }],
      subFields: [
        {
          name: 'title',
          type: 'string',
          defaultValue: 'Please enter the title',
        },
        {
          name: 'content',
          type: 'richText',
          defaultValue: 'Write your content here',
        },
      ],
    },
  ],
});
